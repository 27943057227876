import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'

import Script from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import Banner from '../components/banner'
import Branding from '../components/branding'
import Steps from '../components/steps'
import Gallery4 from '../components/gallery4'
import Footer31 from '../components/footer31'
import './home.css'



const Home = (props) => {
  const[kasterina,setKasterina] = useState(false)
  const[yefkrhig,setyefkrhig] = useState(false)
  const[rimmon,setrimmon] = useState(true)


const slides = document.querySelectorAll('.slide');
let currentSlide = 0;

function openProjectsNext(n) {
  if(rimmon === true){
    setKasterina(true)
    setrimmon(false)
    setyefkrhig(false)
  }
  else if(kasterina === true){
    setKasterina(false)
    setrimmon(false)
    setyefkrhig(true)
  }
  else{
    setKasterina(false)
    setrimmon(true)
    setyefkrhig(false)
  }

}

function openProjectsBack(n) {
  if(rimmon === true){
    setKasterina(false)
    setrimmon(false)
    setyefkrhig(true)
  }
  else if(kasterina === true){
    setKasterina(false)
    setrimmon(true)
    setyefkrhig(false)
  }
  else{
    setKasterina(true)
    setrimmon(false)
    setyefkrhig(false)
  }

}

  return (
    <div className="home-container10">
      <Helmet>
        <title>Dawit Israel</title>
        <meta property="og:title" content="Dawit Israel" />
      </Helmet>
      <Banner></Banner>
      <section className="home-hero">
        <div className="home-main1">
          <div className="home-content10">
            <header className="home-header10">
              <header data-thq="thq-navbar" className="home-navbar-interactive">
                <Branding
                  companyName="Dawit"
                  rootClassName="brandingroot-class-name1"
                ></Branding>
                <div
                  data-thq="thq-navbar-nav"
                  data-role="Nav"
                  className="home-desktop-menu"
                >
                  <div className="home-links1">
                    <a href='#aboutMe' className="home-link1 navLink">
                      About Me
                    </a>
                    <a  href='#projects' className="home-link2 navLink">
                      Projects
                    </a>
                    <a  href='#techStack' className="navLink">
                      Tech Stack
                    </a>
                    <Link to="/contact" className="home-link4 navLink">
                    </Link>
                  </div>
                </div>
                <div data-thq="thq-burger-menu" className="home-burger-menu">
                  <button className="button">
                    
                    <svg viewBox="0 0 1024 1024" className="home-icon10">
                      <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                    </svg>
                  </button>
                </div>
                <div data-thq="thq-mobile-menu" className="home-mobile-menu">
                  <div
                    data-thq="thq-mobile-menu-nav"
                    data-role="Nav"
                    className="home-nav"
                  >
                    <div className="home-container11">
                      <Branding rootClassName="brandingroot-class-name"></Branding>
                      <div
                        data-thq="thq-close-menu"
                        className="home-menu-close"
                      >
                        <svg viewBox="0 0 1024 1024" className="home-icon12">
                          <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                        </svg>
                      </div>
                    </div>
                    <div className="home-links2">
                      <a href='#aboutMe' className="home-link5 navLink">
                        About Me
                      </a>
                      <a href='#projects' className="home-link6 navLink">
                        Projects
                      </a>
                      <a href='#techStack' className="home-link7 navLink">
                        Tech Stack
                      </a>
                     
                    </div>
                    <div className="home-icon-group">
       
                    <a href='https://www.instagram.com/dawa_sonny?igsh=MWJ4aGhxaHRudHpjcQ==' target='_blank'>
            <svg
              viewBox="0 0 877.7142857142857 1024"
              className=" home-icon16"
            >
              <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
            </svg>
            </a>
            <a href='https://www.linkedin.com/in/dawit-israel-8b7aaa244?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app' target='_blank'>
            <svg
              viewBox="0 0 877.7142857142857 1024"
              className="home-icon16"
            >
              <path d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"></path>
            </svg>
            </a>
        
            <a href='https://t.me/dawa_sonny' target='_blank'>
          
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-telegram  home-icon16" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906q-1.168.486-4.666 2.01-.567.225-.595.442c-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294q.39.01.868-.32 3.269-2.206 3.374-2.23c.05-.012.12-.026.166.016s.042.12.037.141c-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8 8 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629q.14.092.27.187c.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.4 1.4 0 0 0-.013-.315.34.34 0 0 0-.114-.217.53.53 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09"/>
</svg>
</a>
                      
                    
                    </div>
                  </div>
                </div>
              </header>
            </header>
            <div className="home-hero-section">
              <div className="home-header11">
                <h1 className="home-heading1">Hi, I&apos;m Dawit Israel</h1>
                <p className="home-caption10">
                  I develop well-structured, responsive, and engaging web
                  applications, using modern front-end tools to solve design
                  problems and ensure smooth, user-friendly experiences.
                </p>
              </div>
              <div className="home-buttons1">
              <Link to={'/contact'}>
              
                <button className="home-button12 button" style={{display:'flex',backgroundColor:'black',color:'white'}}>
                  <span>  Start a conversation
                  </span>
                </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="home-image10">
            <a style={{display:'flex',justifyContent:'center'}}href='https://ephratawi.s3.us-east-1.amazonaws.com/White+and+Black++Modern+New+Graduate+Professional+Resume+(1).pdf' target='_blank' className="button home-button13">Resume</a>
          </div>
        </div>
        <div className="home-details-container">
          <div className="home-details1">
            <div className="home-container12">
              <div className="home-container13">
                <div className="home-category1"></div>
              </div>
            </div>
            <div className="home-category2">
              <img alt="image" src="/details-2.svg" className="home-image11" />
              <p className="home-text12">
                I build responsive web applications using media queries,
                flexible layouts, and mobile-first design to ensure a seamless
                experience across all devices.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </p>
            </div>
            <div className="home-category3">
              <img alt="image" src="/details-3.svg" className="home-image12" />
              <p className="home-text13">
                Develop websites that adapt seamlessly to different screen sizes
                (desktop, tablet, mobile) using responsive design techniques.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </p>
            </div>
            <div className="home-category4"></div>
            <div className="home-category5">
              <img alt="image" src="/details-1.svg" className="home-image13" />
              <p className="home-text14">
                Ensure websites load quickly by optimizing images, minifying
                CSS/JS, and implementing lazy loading.
              </p>
            </div>
          </div>
        </div>
      </section>
      
      <section className="home-quote-one" id='aboutMe'>

        <div className="home-quote-content1">
        <h1 className="home-heading1 text-left">About Me</h1>

          <h2 className="home-caption15">
          Hello! I’m Dawit, a front-end developer from Addis Ababa, Ethiopia, with four years of experience in building fast, scalable, and user-friendly web applications. I specialize in creating interactive, responsive interfaces using HTML, CSS, JavaScript, React.js, and Firebase, with experience on the back-end using Node.js. I’m passionate about delivering seamless user experiences that perform flawlessly across devices.

With hands-on experience integrating Firebase for real-time databases, authentication, and cloud storage, and building server-side functionality using Node.js, I’m able to create full-stack applications that are both powerful and scalable. Whether I’m designing a responsive UI, managing state with React, developing back-end services with Node.js, or handling real-time data with Firebase, I focus on writing clean, efficient code that ensures both performance and usability.

I’m proficient with Git for version control, enabling smooth collaboration with teams and efficient project management. My goal is to blend technical expertise with creativity, delivering applications that are not only visually engaging but also intuitive, secure, and high-performing.

I’m always eager to learn and stay up-to-date with the latest trends in front-end and back-end development. I’m committed to building solutions that users love and that stand the test of time.

I’m open to remote work opportunities and would love to collaborate with teams globally. If you’re looking for a full-stack developer with a user-centric approach and a passion for creating scalable, high-quality web applications, feel free to reach out—I’d love to connect!
          </h2>
       
        </div>
      </section>
      <section className="home-mission" id='techStack'>
        <div className="home-content11">
          <div className="home-main2">
            <span className="home-title1 ">Tech Stack</span>
            <div className="home-header12">
              <h2 className="home-heading2">
                {' '}
                I work with a wide range of modern tools and technologies to
                build responsive, dynamic, and user-friendly web applications.
              </h2>
            </div>
          </div>
          <div className="home-highlights1">
            <div className="home-highlight1">
              <h3 className="home-title2 mb-2 mt-14">Why This Tech Stack?</h3>
              <p className="home-description1">
                This tech stack allows me to build fast, reliable, and scalable
                web applications.
              </p>
            </div>
            <div className="home-highlight2"></div>
          </div>
        </div>
        <div className="home-container14">
          <Steps
            caption4="Javascript"
            caption1={
              <Fragment>
                <span className="home-caption11">Canva</span>
              </Fragment>
            }
            caption2={
              <Fragment>
                <span className="home-caption12">RESTful APIs</span>
              </Fragment>
            }
            caption41="React Js"
            caption411="Node Js"
            caption4111="Firebase"
            caption41111="Git"
            caption411111="Css3"
            caption411115="Tailwind Css"
            caption411116="Html5"
            caption4111111="CSS3"
          ></Steps>
          
        </div>
      </section>

  
      <section className="home-steps" style={{backgroundColor:'#E8E6DA'}}>
        <div className="home-content13">
          <div className="home-information">
            <div className="home-main4">
              <div className="home-header14">
                <h2 className="home-heading9">
                  Here’s what you’ll experience when we work together
                </h2>
              
                <p className="home-caption15">
                  <span style={{color:'green'}}>✔️</span>   A design that aligns with your brand and business goals
                </p>
                <p className="home-caption16">
                  <span style={{color:'green'}}>✔️</span>   Projects completed on schedule, with no surprises
                </p>
                <p className="home-caption17">
                  <span style={{color:'green'}}>✔️</span>   High-quality design and development at a price that fits
                  your budget—without compromising on value
                </p>
                <p className="home-caption18">
                  <span style={{color:'green'}}>✔️</span>   Dedicated attention to every project with a focus on quality
                  and detail
                </p>
                <p className="home-caption19">
                  <span style={{color:'green'}}>✔️</span>   From concept to launch, I’ll design and build a
                    complete, functional website that drives results
                    and provides a seamless user experience.
                </p>
           
                <p className="home-caption21">
                  <span style={{color:'green'}}>✔️</span>   Regular updates and transparent conversations throughout the
                  process
                </p>
              </div>
            </div>
         
          </div>
          {/* <section className="home-quote-two">
        <div className="home-content17">
          <div className="home-video1">
            <video
              src
              poster="/video-1400w.png"
              className="home-video2"
            ></video>
            <div className="home-play">
              <div className="home-button24">
                <svg
                  viewBox="0 0 808.5942857142857 1024"
                  className="home-icon20"
                >
                  <path d="M790.857 529.714l-758.857 421.714c-17.714 9.714-32 1.143-32-18.857v-841.143c0-20 14.286-28.571 32-18.857l758.857 421.714c17.714 9.714 17.714 25.714 0 35.429z"></path>
                </svg>
              </div>
            </div>
          </div>
      
        </div>
      </section> */}
        </div>
      </section>
   
      <section className="home-updates" id='projects'>
        <div className="home-content19">
          <div className="home-details2">
            <div className="home-header19">
              <h2 className="home-heading9">
              Take a look at some of the projects I've delivered for clients.
              </h2>
            
            </div>
          
          </div>
          <div className="home-slider1">
            <div className="home-slides">
              {
                kasterina === true ?
                <div className="slide">
                <img
                  alt="image"
                  src="https://ephratawi.s3.us-east-1.amazonaws.com/Home-Page-Kasterina-Cakes-12-16-2024_12_37_AM.png "
                  className="mb-8"
                />
                <span className="">
                <a href='https://kasterina.top' style={{marginTop:'5px',marginBottom:'8px'}} className="home-text34 hover:underline mb-3" target='_blank'>Kasterina Cakes</a>

                  <p className="home-caption15" style={{marginTop:'2px'}}>
                  I developed a custom website for a cake business using React JS, Tailwind CSS, Firebase, and Canva. The site allows customers to place orders online, track their deliveries, and browse the full range of cake offerings. With an intuitive, user-friendly design and seamless functionality, it enhances the customer experience while streamlining the ordering process.
              </p>
                </span>
              </div>:
              rimmon === true ?
              <div className=" slide">
              <img
                alt="image"
                src="https://ephratawi.s3.us-east-1.amazonaws.com/Ephratawi-Design-11-14-2024_01_35_PM.png"
                className=" mb-8"
              />
              <a href='https://ephratawi.top'  className="home-text34 hover:underline mb-3" target='_blank'>Ephratawi Habesha Store</a>
              <br></br>
              <p className="home-caption15" style={{marginTop:'2px'}}>
              I designed and developed a comprehensive website for a Habesha cloth store, featuring an integrated payment processing system and an efficient delivery tracking feature. The platform offers both customers and the store real-time access to order details. Additionally, the store can seamlessly track key performance metrics, including revenue, order volume, customer communications, and the status of active orders.
            </p>
            </div>
            :
 
            <div className="slide">
            <img
              alt="image"
              src="https://ephratawi.s3.us-east-1.amazonaws.com/Yefikir-Hig-11-14-2024_02_37_PM.png"
              className="mb-8"
            />
            <span>
              <a href='https://yefkrhig.com'  className="home-text34 hover:underline mb-3" target='_blank'>Yefikir Hig</a>
              <br></br>
              <p className="home-caption15" style={{marginTop:'2px'}}>
              I developed a website for a charity organization using React, Tailwind CSS, and Firebase. The site features a clean, responsive design to showcase the charity’s mission and events. Firebase is used to securely store data submitted by users, allowing the organization to easily manage and access information sent through the contact forms.
          </p>
            </span>
          </div>
              }
            
             
            </div>
            <div className="home-buttons-container">
              <div className="home-buttons2">
                <button id="previous-slide" className="home-previous button" onClick={openProjectsBack}>
                  <svg viewBox="0 0 1024 1024" className="home-icon24">
                    <path d="M32 512l480 480v-288h512v-384h-512v-288z"></path>
                  </svg>
                </button>
                <button id="next-slide" className="home-next button" onClick={openProjectsNext}>
                  <svg viewBox="0 0 1024 1024" className="home-icon26">
                    <path d="M992 512l-480-480v288h-512v384h512v288z"></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      
      </section>
      <div className="home-donate">
        <div className="home-content18">
          <div className="home-header18">
            <span className="home-heading8">
              <br></br>
              <span>Would you like to work together on something?</span>
            </span>
            <span className="home-caption28">
              I&apos;m always open to exploring product design projects or
              potential partnership opportunities.
            </span>
          </div>
          <div className="home-amount">
            <div className="home-row3">
              <Link to={'/contact'}>
              <button className="home-button25 button" style={{display:'flex',backgroundColor:'black',color:'white'}}>
                Start a conversation
              </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    
 
      
    
     
      <Footer31
          link1={
            <Fragment>
              <span className="home-text40">Home</span>
            </Fragment>
          }
          link2={
            <Fragment>
              <span className="home-text41">Petitions</span>
            </Fragment>
          }
          link3={
            <Fragment>
              <span className="home-text42">Animal Welfare</span>
            </Fragment>
          }
          link4={
            <Fragment>
              <span className="home-text43">About Us</span>
            </Fragment>
          }
          link5={
            <Fragment>
              <span className="home-text44">Donate</span>
            </Fragment>
          }
          termsLink={
            <Fragment>
              <span className="home-text45">Terms of Use</span>
            </Fragment>
          }
          cookiesLink={
            <Fragment>
              <span className="home-text46">Cookies Policy</span>
            </Fragment>
          }
          privacyLink={
            <Fragment>
              <span className="home-text47">Privacy Policy</span>
            </Fragment>
          }
          rootClassName="footer31root-class-name"
        ></Footer31>
      <div>
        <div className="home-container20">
        
        </div>
      </div>
    </div>
  )
}

export default Home
